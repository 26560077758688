import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { Spinner } from '~/components/ui/Spinner'
import { Slottable } from '~/components/ui/utils/Slottable'
import { cn } from '~/utils/cn'

export const buttonStyle = cva(
  'relative isolate flex items-center whitespace-nowrap rounded-lg border border-border/8 bg-border/[0.01] px-3 text-xs font-medium text-border transition-all hover:bg-under/4 active:bg-under/6 disabled:pointer-events-none disabled:opacity-48',
  {
    variants: {
      variant: {
        primary: '',
        gradient: [
          'group border-transparent bg-gradient-to-r from-landing-components-gradient-from via-landing-components-gradient-via to-landing-components-gradient-to bg-clip-text text-transparent gradient-ring gradient-ring-offset-0 gradient-ring-width-[1px] before:bg-gradient-to-r before:from-landing-components-gradient-from before:via-landing-components-gradient-via before:to-landing-components-gradient-to',
          'after:absolute after:-inset-2 after:rounded-[inherit] after:bg-gradient-to-br after:from-landing-components-gradient-from after:via-landing-components-gradient-via after:to-landing-components-gradient-to after:opacity-5 after:blur-md',
        ],
      },
      size: {
        md: 'h-10',
        sm: 'h-8 py-2.5 ',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<VariantProps<typeof buttonStyle>, 'icon'> {
  asChild?: boolean
  isLoading?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant,
      asChild = false,
      isLoading,
      size,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(
          buttonStyle({
            variant,
            size,
            className,
          }),
          isLoading && '[&>*:not(.button-spinner)]:invisible'
        )}
        ref={ref}
        {...props}
      >
        <Slottable asChild={asChild} child={children}>
          {(child) => (
            <>
              {typeof child === 'string' ? <span>{child}</span> : child}
              {isLoading && <Spinner className="button-spinner absolute" />}
            </>
          )}
        </Slottable>
      </Comp>
    )
  }
)
Button.displayName = 'Button'
